.solicitacoesTitle {
    position: relative;
    font-size: 120%;
    font-weight: bold;
    color: #808080;
    border-radius: 4px 4px 0 0;
    line-height: 45px;
    height: 45px;
    margin: 1px 1px 0 1px;
    border-bottom: 1px solid #e5e5e5;
    padding-left: 20px;
    background: #f5f5f5;
    float: left;
}



.header-componentes-user{
    margin-left: 8px;
    text-align: left;
}