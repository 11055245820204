
.div-body-home{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    overflow: hidden;
}
.div-text-welcome{
    color: white;
    text-align: left;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: white;
    overflow: hidden;   
}

.div-body-items{
 
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;  
    overflow: scroll;
}

.box-options-buttons .box-buttons-itens {
    background-color: rgba(32, 32, 32, 0.678);
    color: white;    
    width: 100%;
    height: 100%;
    margin: 2px;
    text-align: left;
    
}

.box-buttons-text{
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 5%;
    height: 200px;
}

.box-buttons-button{
    margin-left: 5%;
    height: 70px;
}
