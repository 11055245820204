.active {
    background-color: rgba(119, 155, 233, 0.452); 
    color: black;

} 

.menus-navigation{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-decoration: none; 
    top: 50%;
    left: 50%;
    margin: 0 20px 0 0; 
    border: 1px solid rgba(0, 0, 0, 0);
    height: 68px;
    padding-left: 3px;
    padding-right: 3px;
    width: 105px;
    text-align: center;
    color: #ffff    
}

.menus-navigation:hover {
    border-left: 1px solid #000000; 
    border-right: 1px solid #000000; 
    color: #000000;
}

.logoSirrus{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex; 
    width: 100%; 
    position: absolute; 
    pointer-events: none;
}

.logoSirrus img {
    margin-left: auto; 
    margin-right: auto;
}