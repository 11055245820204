
.div-login-fundo {
    width: 100%;
    height: 100vh;
    Background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    place-items: center;
    justify-content: center;
    align-items: center; 
    text-align: center;
}

.box-login {
    position: absolute;
    width: 360px;
    height: 360px;
    box-shadow: 0px 0px 10px black;
    padding: 20px;
    box-sizing: border-box; 
    background: white;
    color: black;
    border-radius: 5% 5%;
}
    
.div-by {
    font-size: 14px;
    position: absolute; 
    bottom: 4px; 
    width: 90%; 
}