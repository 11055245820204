.chartContainer {
    width: 99vw;
    max-width: 99vw;
    overflow-y: scroll;
}
.chartContainerBody {
    height: calc(82vh - 80px);
    width: 5000px;
    overflow-y: hidden;
}
.chartBox {
    width: 99vw;
    padding: 20px;
    border-radius: 20px;
    border: solid 3px rgba(255, 26, 104, 1);
    white-space: nowrap;
    overflow: hidden !important;
}
.chartCard {
    width: 100vw;
    height: calc(82vh - 40px);
    display: flex;
    align-self: center;
    justify-content: flex-start;
}

@media( max-width: 1280px ) {
    .chartContainer {
        width: 99%;
        max-width: 99%;
        overflow-y: hidden;
    }
    .chartBox {
        width: 99%;
        height: 89%;
        padding: 0px;
        border-radius: 20px;
        border: solid 3px rgba(255, 26, 104, 1);
        white-space: nowrap;
        overflow: hidden !important;
    }
}