.ServicesRequest {

}

.request-container-groups {
  bottom: 0;
  left: 0;
  margin-bottom: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 8px;
  right: 0;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  display: flex;
  height: 82% ;
}

.statusGroups{
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  white-space: normal;
  width: 100%!important;
  height: 100%;
  border: 1px solid rgb(180, 174, 174);
  border-radius: 0.75em;
  min-width: 300px;
}

.statusGroups-header{
  width: 93%;
  display: flex;
  font-size: medium;
  text-align: center;
  margin: 10px;
}

.itemRequest {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
  
.itemRequest li {
  width: 100%;
  display: flex;
  justify-content: center;   
  margin-bottom: 1em;
}
  
.request-title{
  width: 100%;
  font-size: small;
}

.request-counts{
  flex: 1;
  margin-top: 5px; 

}

.statusGroups-body{
  height: 100%;
  overflow-y: scroll;

}

.droppable-areaEmpty {
  text-align: center;
}

.iconsHeader {
  display: inherit;
  margin-top: 2px;
  margin-right: 2px;
}

@media( max-width: 1280px ) {
  .request-container-groups {
    height: 75% ;
  }
  
}