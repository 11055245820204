

.body-search-bar {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: white;
}



.search-bar-input {
    display: block;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 1rem;    
 
}

.box-search-input{
    margin-left: 2;
    flex: 1;
    margin-bottom: 0.7;
}

.box-search-box {
    background-color: white;
    display: flex;
    align-items: flex-end;
    float: center; 
    padding-left: 4px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 25px;
}

.div-helpdesk{
    align-items: center;
}

.div-pagination{ 
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.box-view-pub {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
