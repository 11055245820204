
.div-login-fundo-admin {
    width: 100%;
    height: 100vh;
    Background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    text-align: center;
}



.box-login-admin {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 360px;
    height: 450px;
    margin: auto;
    box-shadow: 0px 0px 10px black;
    padding-top: 25px;
    box-sizing: border-box; 
    background: white;
    color: black;
    border-radius: 5% 5%;
}

.div-by_admin {
    font-size: 14px;
    position: absolute; 
    bottom: 4px; 
    width: 100%; 
}
      
@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

*{
    margin: 0px;
    padding: 0px;
}

