.box-card-helpdesk {
    
}

.box-card-helpdesk section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
}
.div-img-card-helpdesk {
    position: relative;
    width: 300px;
    height: 200px;
}

.div-img-card-helpdesk img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;   
}

.title-pub-help  {
    cursor: pointer;

}