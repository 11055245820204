.cardRequest{
    width: 95%; 
    border-radius: 0.75em;
    border: 1px solid rgba(0, 0, 0, 0.24); 
    min-width: 80%;
    font-size: small;
}

.cardRequest header {
    align-items: center;
    display: flex;
    background-color: rgba(143, 143, 143, 0.151);
    overflow: hidden;
    text-align: center;
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardRequest header p {
    margin: 3px;
}

.cardRequest-client {
    width: 100%;
}

.iconsHeader {
    flex: 1;
    justify-content: end;
}

.cardRequest-title{
    display: inline;
    vertical-align: middle;
    text-align: center; 
}

.cardRequest-description{
    margin: 10px;
    vertical-align: middle;
    text-align: center; 
    min-height: 70px;
}

