.box-publications {
    height: 100%;
}

.breadcrumbs {
    text-decoration: none;
    color: inherit;
    margin-right: 4px;  
}


.breadcrumbs:hover {
    text-decoration: underline;  
    color: black; 
    cursor: pointer;
  }


.div-helpdesk{
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 92%;
    padding-bottom: 0;
}

.pagination-pubs{ 
    margin-top: 8px;
    height: 12%;
    display: flex;
    justify-content: center;
}

.box-view-pub {
    
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

