.sweet-loading {
    background-color: #000000d3;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner1 {
	border: 5px solid #00000000;
	border-left-color: #00CC99;
    border-top-color: #00CC99;
	border-radius: 100%;
	height: 70px;
	width: 70px;
}

.spinner2 {
	border: 5px solid #00000000;
	border-right-color: #00CC99;
    border-bottom-color: #00CC99;
	border-radius: 100%;
	height: 50px;
	width: 50px;
    margin: 5px;
    position: absolute;
}

@keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes loadingInverse {
	0% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(0);
	}
}

.spinner1.is-animating {
	animation: loading 1s linear infinite;
}

.spinner2.is-animating {
	animation: loadingInverse 1s linear infinite;
}