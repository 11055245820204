.box-video{

    float: center;
    border: 2px solid;
    border-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 2%;
    overflow:hidden  ;
}

.dialog-video{
    border-radius: 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
}

